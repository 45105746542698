var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Optimize",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.optimize,
      callback: function ($$v) {
        _vm.optimize = $$v;
      },
      expression: "optimize"
    }
  })], 1), _c('v-col', [_c('v-radio-group', {
    attrs: {
      "label": "Shape",
      "mandatory": "",
      "row": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.shape,
      callback: function ($$v) {
        _vm.shape = $$v;
      },
      expression: "shape"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "L",
      "value": "L"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "T",
      "value": "T"
    }
  })], 1)], 1), _c('v-spacer')], 1), !_vm.optimize ? [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Material",
      "items": _vm.$store.state.materials,
      "item-text": "data.name",
      "item-value": "data",
      "value": _vm.value.material,
      "disabled": _vm.disabled
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('material', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('bolt-circle', {
    attrs: {
      "value": _vm.value.bolt_circle,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('bolt_circle', $event, _vm.value);
      }
    }
  }), !_vm.optimize ? _c('flange-profile', {
    attrs: {
      "hide-shape": true,
      "value": _vm.value.profile,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('profile', $event, _vm.value);
      }
    }
  }) : _vm._e()] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }