var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tower-nav'), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Report type",
      "items": _vm.report_types,
      "menu-props": {
        bottom: true,
        offsetY: true
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item,
          on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.text))])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.report_type,
      callback: function ($$v) {
        _vm.report_type = $$v;
      },
      expression: "report_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.creatingReport
    },
    on: {
      "click": function ($event) {
        return _vm.createReport();
      }
    }
  }, [_vm._v(" Create report ")])], 1), _vm.showOptions ? _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "label": "Options"
    },
    model: {
      value: _vm.showArgs,
      callback: function ($$v) {
        _vm.showArgs = $$v;
      },
      expression: "showArgs"
    }
  }) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.showArgs ? _c('report-args', {
    attrs: {
      "report-type": _vm.report_type,
      "sections": _vm.towerIntent.data.sections.length
    },
    model: {
      value: _vm.report_args,
      callback: function ($$v) {
        _vm.report_args = $$v;
      },
      expression: "report_args"
    }
  }) : _vm._e()], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.reports,
      "disable-pagination": "",
      "sort-by": "requested",
      "sort-desc": "",
      "hide-default-footer": "",
      "loading": _vm.loading,
      "loading-text": "Loading Reports..."
    },
    scopedSlots: _vm._u([{
      key: `item.action`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-btn-toggle', {
          attrs: {
            "borderless": ""
          },
          model: {
            value: _vm.toggleSelection,
            callback: function ($$v) {
              _vm.toggleSelection = $$v;
            },
            expression: "toggleSelection"
          }
        }, [item.filename || item.error_message || _vm.isTimedOut(item.requested) ? _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteReport(item.uuid);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete ")])], 1) : _vm._e(), item.filename ? _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.downloadReport(item.filename, item.url, item.file_type);
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-download ")])], 1) : _vm._e()], 1)];
      }
    }, {
      key: `item.filename`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.file_type === 'CSV' ? _c('router-link', {
          attrs: {
            "to": {
              name: 'tower-report-csv-viewer',
              params: {
                filename: item.filename
              },
              query: {
                url: item.url,
                report_type: item.report_type
              }
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(item.filename) + " ")]) : item.file_type === 'ZIP' ? _c('a', {
          on: {
            "click": function ($event) {
              return _vm.downloadReport(item.filename, item.url, item.file_type);
            }
          }
        }, [_vm._v(" " + _vm._s(item.filename) + " ")]) : _c('a', {
          attrs: {
            "href": item.url,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(item.filename) + " ")])];
      }
    }, {
      key: `item.requested`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('time-distance', {
          attrs: {
            "time-str": item.requested
          }
        })];
      }
    }, {
      key: `item.age`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm.isCurrent(item.requested) ? _c('v-chip', {
          attrs: {
            "color": "green"
          }
        }, [_vm._v(" Current ")]) : _c('v-chip', {
          attrs: {
            "color": "grey"
          }
        }, [_vm._v(" Outdated ")])];
      }
    }, {
      key: `item.status`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.error_message ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref7) {
              var on = _ref7.on;
              return [_c('span', _vm._g({}, on), [_vm._v("Failed")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(item.error_message))])]) : item.filename ? _c('span', [_vm._v("Success")]) : _vm.isTimedOut(item.requested) ? _c('span', [_vm._v("Timed Out")]) : _c('span', [_vm._v("Pending")])];
      }
    }, {
      key: `item.file_type`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item.file_type ? _c('v-chip', {
          attrs: {
            "small": "",
            "outlined": "",
            "color": _vm.fileTypeColor(item.file_type)
          }
        }, [_vm._v(" " + _vm._s(item.file_type) + " ")]) : _vm.isPending(item) ? _c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "width": 3,
            "color": "primary"
          }
        }) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }