var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": _vm.colWidth
    }
  }, [_c('v-textarea', {
    attrs: {
      "value": _vm.value,
      "filled": "",
      "auto-grow": "",
      "no-resize": "",
      "label": _vm.label,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "color": "primary"
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information ")])];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.notesInfo) + " ")])];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }