var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "elevation": "4"
    }
  }, [_c('v-card-title', {
    staticClass: "pb-2"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-subtitle', {
    staticClass: "pb-0"
  }, [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]), _vm.showRevisionSubtitle ? _c('v-card-subtitle', [_c('span', [_vm._v("Revision: ")]), _c('bordered-text-span', {
    attrs: {
      "text": String(_vm.maxRevisionNumber),
      "padding": "3px"
    }
  })], 1) : _vm._e(), _vm.towerListRoute ? _c('v-data-table', _vm._b({
    attrs: {
      "headers": _vm.mainHeaders,
      "items": _vm.items,
      "sort-by": "lastmodified",
      "sort-desc": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "expanded": _vm.expanded,
      "single-expand": true,
      "item-key": "uuid",
      "loading": _vm.fetching || _vm.actionOccuring
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "expanded-item",
      fn: function (_ref) {
        var headers = _ref.headers,
          item = _ref.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-card', {
          attrs: {
            "elevation": "4"
          }
        }, [_c('v-data-table', {
          attrs: {
            "headers": _vm.sublistHeaders,
            "items": _vm.subItems(item),
            "sort-by": "lastmodified",
            "sort-desc": "",
            "disable-pagination": "",
            "hide-default-footer": "",
            "item-key": "uuid"
          },
          scopedSlots: _vm._u([{
            key: `item.name`,
            fn: function (_ref2) {
              var item = _ref2.item;
              return [_c('span', [_c('v-menu', {
                attrs: {
                  "transition": "slide-x-transition",
                  "offset-x": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function (_ref3) {
                    var on = _ref3.on,
                      attrs = _ref3.attrs;
                    return [item.sketch ? _c('v-btn', _vm._g(_vm._b({
                      attrs: {
                        "icon": ""
                      }
                    }, 'v-btn', attrs, false), on), [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-eye")])], 1) : _vm._e()];
                  }
                }], null, true)
              }, [_c('v-container', {
                staticClass: "ma-0 pa-0",
                attrs: {
                  "fluid": ""
                }
              }, [_c('v-card', {
                staticClass: "d-flex flex-column"
              }, [_c('v-card-title', [_vm._v("Tower Plot")]), _c('v-card-subtitle', {
                staticStyle: {
                  "width": "250px"
                }
              }, [_vm._v(_vm._s(item.name))]), _c('div', {
                style: {
                  'max-height': _vm.$vuetify.breakpoint.xlOnly ? 'auto' : '400px',
                  'overflow': _vm.$vuetify.breakpoint.xlOnly ? 'auto' : 'scroll'
                }
              }, [_c('v-img', {
                staticStyle: {
                  "border": "1px solid black",
                  "max-height": "100%",
                  "max-width": "100%"
                },
                attrs: {
                  "alt": "tower-sketch"
                },
                domProps: {
                  "innerHTML": _vm._s(item.sketch)
                }
              })], 1)], 1)], 1)], 1)], 1), _c('span', [_c('router-link', {
                attrs: {
                  "to": {
                    name: 'tower-intent',
                    params: {
                      uuid: item.uuid
                    }
                  }
                }
              }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)];
            }
          }, {
            key: `item.lastmodified`,
            fn: function (_ref4) {
              var item = _ref4.item;
              return [_c('time-distance', {
                attrs: {
                  "time-str": item.lastmodified
                }
              })];
            }
          }, {
            key: `item.actions`,
            fn: function (_ref5) {
              var item = _ref5.item;
              return [_c('div', {
                staticClass: "d-flex flex-row"
              }, [_c('v-btn', {
                staticClass: "mr-2",
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.copyTower(item);
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-file-multiple")])], 1), _vm.checkIfTowerDraft(item.status_dates) ? _c('v-btn', {
                staticClass: "mr-2",
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.archiveTower(item);
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-package-down")])], 1) : _vm._e(), _vm.checkIfTowerDraft(item.status_dates) ? _c('v-btn', {
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteTower(item);
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)], 1)];
      }
    }, {
      key: `item.name`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', [_c('router-link', {
          attrs: {
            "to": {
              name: 'tower-intent',
              params: {
                uuid: item.uuid
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('span', [_c('v-menu', {
          attrs: {
            "open-on-hover": "",
            "open-delay": "200",
            "offset-x": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref7) {
              var on = _ref7.on,
                attrs = _ref7.attrs;
              return [item.sketch ? _c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-eye")])], 1) : _vm._e()];
            }
          }], null, true)
        }, [_c('v-container', {
          staticClass: "ma-0 pa-0",
          attrs: {
            "fluid": ""
          }
        }, [_c('v-card', {
          staticClass: "d-flex flex-column"
        }, [_c('v-card-title', [_vm._v("Tower Plot")]), _c('v-card-subtitle', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          style: {
            'max-height': _vm.$vuetify.breakpoint.xlOnly ? 'auto' : '400px',
            'overflow': _vm.$vuetify.breakpoint.xlOnly ? 'auto' : 'scroll'
          }
        }, [_c('v-img', {
          staticStyle: {
            "max-height": "100%",
            "max-width": "100%"
          },
          domProps: {
            "innerHTML": _vm._s(item.sketch)
          }
        })], 1)], 1)], 1)], 1)], 1)];
      }
    }, {
      key: `item.status`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm.showStatus(item) ? _c('v-btn', {
          attrs: {
            "small": "",
            "rounded": "",
            "outlined": "",
            "color": `${_vm.status(item).color}`
          }
        }, [_vm._v(" " + _vm._s(_vm.status(item).status) + " ")]) : _vm._e()];
      }
    }, {
      key: `item.lastmodified`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('time-distance', {
          attrs: {
            "time-str": item.lastmodified
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "d-flex flex-row"
        }, [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.copyTower(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-file-multiple")])], 1), _vm.checkIfTowerDraft(item.status_dates) ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.archiveTower(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-package-down")])], 1) : _vm._e(), _vm.checkIfTowerDraft(item.status_dates) ? _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteTower(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()], 1)];
      }
    }], null, true)
  }, 'v-data-table', _vm.optionalProps, false)) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }