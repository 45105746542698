var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "width": "100%",
      "elevation": "3"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_vm._v(" Traits ")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "rounded": "",
      "small": "",
      "disabled": _vm.isGlobal
    },
    on: {
      "click": function ($event) {
        return _vm.addTrait();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" Add Trait ")], 1)], 1), _c('v-card-text', _vm._l(_vm.value, function (trait, idx) {
    return _c('v-row', {
      key: idx,
      attrs: {
        "dense": ""
      }
    }, [_c('v-col', {
      staticClass: "p-0 m-0",
      attrs: {
        "md": "11"
      }
    }, [_c('v-select', {
      attrs: {
        "label": "Trait",
        "items": _vm.traits,
        "value": _vm.value[idx],
        "disabled": _vm.isGlobal,
        "rules": [_vm.formRules.required],
        "width": "30%"
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue([idx], $event, _vm.value);
        }
      }
    })], 1), _c('v-col', {
      staticClass: "align-self-center",
      attrs: {
        "md": "1",
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "overflow-auto",
      attrs: {
        "icon": "",
        "disabled": _vm.isGlobal
      },
      on: {
        "click": function ($event) {
          return _vm.deleteTrait(idx);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }