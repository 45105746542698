var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._b({
    staticClass: "numeric-text-field",
    attrs: {
      "dense": _vm.dense,
      "label": _vm.label,
      "reverse": "",
      "prefix": _vm.units,
      "disabled": _vm.disabled,
      "rules": _vm.localRules,
      "error": _vm.error,
      "error-messages": _vm.errorMessages
    },
    on: {
      "blur": _vm.compute,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.compute.apply(null, arguments);
      },
      "focus": _vm.disableRules,
      "input": _vm.disableRules
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'v-text-field', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }