var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.error_message ? _c('v-alert', {
    attrs: {
      "type": "error",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.error_message) + " ")]) : _vm._e(), _vm.$store.getters.needChangePassword() ? _c('v-card', [_c('v-card-title', [_vm._v("Change password")]), _c('v-card-text', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "append-icon": _vm.showNewPassword ? 'mdi-eye-off' : 'mdi-eye',
      "rules": [_vm.formRules.required, _vm.localRules.min],
      "type": _vm.showNewPassword ? 'text' : 'password',
      "name": "input-10-1",
      "label": "New password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showNewPassword = !_vm.showNewPassword;
      }
    },
    model: {
      value: _vm.newPassword,
      callback: function ($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  }), _c('v-text-field', {
    attrs: {
      "append-icon": _vm.showRePassword ? 'mdi-eye-off' : 'mdi-eye',
      "rules": [_vm.formRules.required, _vm.localRules.min, _vm.localRules.match],
      "type": _vm.showRePassword ? 'text' : 'password',
      "name": "input-10-2",
      "label": "Reenter password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showRePassword = !_vm.showRePassword;
      }
    }
  }), _c('v-btn', {
    on: {
      "click": function ($event) {
        return _vm.submitPasswordChange();
      }
    }
  }, [_vm._v(" Change Password ")])], 1)], 1)], 1) : _c('v-card', [_c('v-card-title', [_vm._v("Sign In")]), _c('v-card-text', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "label": "User name",
      "data-lpignore": "false"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.signIn();
      }
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('v-text-field', {
    attrs: {
      "append-icon": _vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',
      "type": _vm.showPassword ? 'text' : 'password',
      "label": "Password",
      "autocomplete": "on",
      "data-lpignore": "false"
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.signIn();
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-btn', {
    on: {
      "click": function ($event) {
        return _vm.signIn();
      }
    }
  }, [_vm._v(" Sign in ")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }