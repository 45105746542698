var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    style: {
      zIndex: _vm.options.zIndex
    },
    attrs: {
      "max-width": _vm.options.width
    },
    on: {
      "click:outside": _vm.cancel,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.cancel.apply(null, arguments);
      }
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "dense": "",
      "flat": "",
      "color": _vm.options.color
    }
  }, [_c('v-toolbar-title', {
    staticClass: "text-body-1 font-weight-bold grey--text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1), _c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.message,
      expression: "!!message"
    }],
    staticClass: "pa-4 black--text",
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }), _c('v-card-actions', {
    staticClass: "pt-3"
  }, [_c('v-spacer'), !_vm.options.noconfirm ? _c('v-btn', {
    staticClass: "body-2 font-weight-bold",
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" Cancel ")]) : _vm._e(), _c('v-btn', {
    staticClass: "body-2 font-weight-bold",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.agree
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }