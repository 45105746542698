<template>
  <v-row>
    <v-col cols=5>
      <v-switch
        v-model="useIEC"
        label="Use IEC default"
        :disabled="disabled"
      />
      <v-simple-table v-if="!useIEC" dense>
        <tbody>
          <tr
            v-for="(thicknessRange, index) in thicknessRanges"
            :key="index"
          >
            <td>{{ thicknessRange[0] }} &lt; t &le; {{ thicknessRange[1] }} </td>
            <td >
              <numeric-text-field
                label="Yield Strength"
                :value="value.yield_strengths[index]"
                units="Mpa"
                :disabled="disabled"
                required
                @input="updateValue(['yield_strengths', index], $event, value)"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"
import _ from "lodash"

const emptyFactoredYield = {
  thicknesses: [16, 40, 100, 150, 200, 250, 400],
  yield_strengths: [],
}

export default {
  name: "FactoredYield",
  components: {
    NumericTextField,
  },
  props: {
    value: {
      type: Object,
      //default: () => (emptyFactoredYield),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    useIEC: {
      get() {
        return this.value == null
      },
      set(v) {
        this.$emit("input", v ? null : emptyFactoredYield)
      },
    },
    thicknessRanges () {
      return _.zip([0, ...this.value.thicknesses.slice(0, -1)], this.value.thicknesses)
    },
  },
}
</script>

<style scoped></style>
