var render = function render(){
  var _vm$value$thickness_r, _vm$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', {
    staticClass: "my-1",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" Inventory ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "value": _vm.value.name,
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Material",
      "items": _vm.materialNames,
      "required": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.material,
      callback: function ($$v) {
        _vm.material = $$v;
      },
      expression: "material"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Nominal Width",
      "units": "m",
      "value": _vm.value.nominal_width,
      "required": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('nominal_width', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Min Width",
      "units": "m",
      "value": _vm.value.min_width,
      "required": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('min_width', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Max Length",
      "units": "m",
      "value": _vm.value.max_length,
      "required": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('max_length', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "my-1",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" Thicknessess ")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-row', [_c('custom-data-table', {
    attrs: {
      "headers": _vm.thickessRangeHeaders,
      "items": (_vm$value$thickness_r = (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.thickness_ranges) !== null && _vm$value$thickness_r !== void 0 ? _vm$value$thickness_r : [],
      "value-units": _vm.thicknessRangeValueUnits,
      "data-empty-row": _vm.thicknessRangeEmptyRow,
      "optional-props": {
        'disable-pagination': true,
        'hide-default-footer': true,
        'dense': true
      },
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('thickness_ranges', $event, _vm.value);
      }
    }
  })], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('comma-separated-list', {
    attrs: {
      "value": _vm.value.thicknesses,
      "label": "Legacy Comma-separated Thicknesses",
      "units": "mm",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('thicknesses', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }