var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Use IEC default",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.useIEC,
      callback: function ($$v) {
        _vm.useIEC = $$v;
      },
      expression: "useIEC"
    }
  }), !_vm.useIEC ? _c('v-simple-table', {
    attrs: {
      "dense": ""
    }
  }, [_c('tbody', _vm._l(_vm.thicknessRanges, function (thicknessRange, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(thicknessRange[0]) + " < t ≤ " + _vm._s(thicknessRange[1]) + " ")]), _c('td', [_c('numeric-text-field', {
      attrs: {
        "label": "Yield Strength",
        "value": _vm.value.yield_strengths[index],
        "units": "Mpa",
        "disabled": _vm.disabled,
        "required": ""
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue(['yield_strengths', index], $event, _vm.value);
        }
      }
    })], 1)]);
  }), 0)]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }