var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-container', [_c('v-card', {
    staticStyle: {
      "margin": "0 auto"
    },
    attrs: {
      "elevation": "4",
      "max-width": "1200px"
    }
  }, [_c('v-card-title', {
    staticClass: "pb-2 mb-2"
  }, [_vm._v(" Load Sets ")]), _vm.currentTurbineSelected ? _c('v-card-subtitle', [_vm._v(" Filtered by Turbine: " + _vm._s(_vm.turbineName) + " ")]) : _c('v-card-subtitle', [_vm._v(" Filtered by Other ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.filteredLoadSetsByTurbine,
      "sort-by": "lastmodified",
      "sort-desc": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "item-key": "uuid",
      "loading": _vm.fetching || _vm.deletingLoadset
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'edit-load-set',
              params: {
                uuid: item.uuid
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: `item.lastmodified`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('time-distance', {
          attrs: {
            "time-str": item.lastmodified
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteLoadset(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }