var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-textarea', _vm._b({
    model: {
      value: _vm.displayText,
      callback: function ($$v) {
        _vm.displayText = $$v;
      },
      expression: "displayText"
    }
  }, 'v-textarea', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }