var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4",
    style: {
      width: _vm.headers.length <= 4 ? '450px' : '100%'
    }
  }, [_vm.alertMessage ? _c('v-alert', {
    attrs: {
      "type": "warning",
      "dense": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]) : _vm._e(), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm.showAddCsvBtn ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "loading": _vm.isSelecting,
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              return _vm.handleCsvImport();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" mdi-table-large-plus ")])], 1) : _vm._e()];
      }
    }])
  }, [_vm._v(" Add Data from CSV ")]), _c('input', {
    ref: "uploader",
    staticClass: "d-none",
    attrs: {
      "id": "file",
      "type": "file",
      "accept": ".csv"
    },
    on: {
      "change": function ($event) {
        return _vm.onFileChanged($event);
      }
    }
  })], 1), _c('v-card-subtitle', [_vm._v(_vm._s(_vm.subtitle))]), _vm.items.length === 0 ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.addElement();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")])], 1)], 1) : _vm._e(), _c('v-data-table', _vm._b({
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: `item.add_row`,
      fn: function (_ref2) {
        var index = _ref2.index;
        return [!_vm.disabled ? _c('v-col', {
          staticClass: "pa-0 ma-0 d-flex",
          staticStyle: {
            "height": "100%"
          }
        }, [index === 0 ? _c('v-btn', {
          staticClass: "align-self-start",
          staticStyle: {
            "transform": "translateY(-17px)"
          },
          attrs: {
            "fab": "",
            "x-small": "",
            "elevation": "3"
          },
          on: {
            "click": function ($event) {
              return _vm.addElement();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-plus")])], 1) : _vm._e(), _c('v-btn', {
          staticClass: "align-self-end",
          staticStyle: {
            "transform": "translateY(17px)",
            "position": "absolute",
            "z-index": "2"
          },
          attrs: {
            "fab": "",
            "x-small": "",
            "elevation": "3"
          },
          on: {
            "click": function ($event) {
              return _vm.insertElementAt(index);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-plus")])], 1)], 1) : _vm._e()];
      }
    }, {
      key: `item.name`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-text-field', {
          staticStyle: {
            "width": "12rem"
          },
          attrs: {
            "reverse": "",
            "disabled": _vm.disabled
          },
          model: {
            value: item.name,
            callback: function ($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })];
      }
    }, _vm._l(_vm.valueUnits, function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref4) {
          var item = _ref4.item;
          return [_c('numeric-text-field', {
            key: header.value,
            staticStyle: {
              "width": "8rem",
              "padding-top": "0"
            },
            attrs: {
              "units": header.units ? header.units : null,
              "required": "",
              "disabled": _vm.disabled
            },
            on: {
              "input": function ($event) {
                return _vm.numericFieldChange();
              }
            },
            model: {
              value: item[header.value],
              callback: function ($$v) {
                _vm.$set(item, header.value, $$v);
              },
              expression: "item[header.value]"
            }
          })];
        }
      };
    }), {
      key: `item.warn_only`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-simple-checkbox', {
          attrs: {
            "ripple": false,
            "disabled": _vm.disabled
          },
          model: {
            value: item.warn_only,
            callback: function ($$v) {
              _vm.$set(item, "warn_only", $$v);
            },
            expression: "item.warn_only"
          }
        })];
      }
    }, {
      key: `item.action`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [!_vm.disabled ? _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.deleteElement(_vm.items.indexOf(item));
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  }, 'v-data-table', _vm.optionalProps, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }