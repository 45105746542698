var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tower-nav'), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-spacer'), _c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.towerIntent.srf_plot)
    }
  })]), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }