var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    style: {
      'position': 'sticky',
      'top': _vm.appbarBreakpoint,
      'z-index': 20
    },
    attrs: {
      "dense": "",
      "dark": "",
      "elevate-on-scroll": ""
    }
  }, [_c('v-tabs', {
    attrs: {
      "align-with-title": ""
    }
  }, [_c('v-tab', {
    attrs: {
      "to": {
        name: 'tower-intent',
        params: {
          uuid: _vm.$route.params.uuid
        }
      }
    }
  }, [_vm._v(" Design ")]), _c('v-tab', {
    attrs: {
      "to": {
        name: 'tower-srf-plot',
        params: {
          uuid: _vm.$route.params.uuid
        }
      }
    }
  }, [_vm._v(" SRF Plot ")]), _c('v-tab', {
    attrs: {
      "to": {
        name: 'tower-reports',
        params: {
          uuid: _vm.$route.params.uuid
        }
      }
    }
  }, [_vm._v(" Reports ")])], 1), _vm._t("actions")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }