var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', {
    class: {
      'mb-2': !_vm.modNumExists
    },
    attrs: {
      "label": "Tower Family",
      "items": _vm.modelNumberItems,
      "item-text": "model_name",
      "item-value": "model_name",
      "hint": !_vm.modNumExists ? `New Model Name Detected: ${_vm.modelName}` : '',
      "persistent-hint": "",
      "return-object": false,
      "rules": [_vm.formRules.required, function (v) {
        return isNaN(v) || 'Value cannot be a number.';
      }],
      "menu-props": {
        bottom: true,
        offsetY: true
      },
      "dense": "",
      "hide-no-data": "",
      "disabled": _vm.comboboxDisabled || _vm.disabled,
      "outlined": _vm.outlined
    },
    model: {
      value: _vm.modelNameStatus,
      callback: function ($$v) {
        _vm.modelNameStatus = $$v;
      },
      expression: "modelNameStatus"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }