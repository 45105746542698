var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Radial pattern",
      "items": _vm.patternTypes,
      "value": _vm.value.type,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateType(_vm.patternSchemas, $event);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('comma-separated-list', {
    attrs: {
      "label": "Orientations",
      "units": "deg",
      "value": _vm.value.orientations,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('orientations', $event, _vm.value);
      }
    }
  })], 1), _vm.value.type === 'ConstWidthPattern' ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Width",
      "units": "m",
      "value": _vm.value.width,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('width', $event, _vm.value);
      }
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }