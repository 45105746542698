var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "ma-2",
          attrs: {
            "dark": "",
            "color": "primary"
          },
          on: {
            "click": _vm.show
          }
        }, on), [_vm._v(" Create new tower ")])];
      }
    }]),
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("New Tower")])]), _c('v-card-text', [_c('v-container', [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Tower name",
      "rules": [_vm.formRules.required]
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('library-select', {
    attrs: {
      "label": "Turbine",
      "items": _vm.$store.state.turbines,
      "item-text": "data.name",
      "item-value": "uuid"
    },
    model: {
      value: _vm.turbine,
      callback: function ($$v) {
        _vm.turbine = $$v;
      },
      expression: "turbine"
    }
  }), _c('model-number-select', {
    ref: "modelNumberRef",
    attrs: {
      "turbine": _vm.turbine
    },
    model: {
      value: _vm.modelNumber,
      callback: function ($$v) {
        _vm.modelNumber = $$v;
      },
      expression: "modelNumber"
    }
  }), _c('library-select', {
    attrs: {
      "label": "Design Bases",
      "items": _vm.$store.state.designBases,
      "item-text": "data.name",
      "item-value": "uuid"
    },
    model: {
      value: _vm.designBasis,
      callback: function ($$v) {
        _vm.designBasis = $$v;
      },
      expression: "designBasis"
    }
  }), _c('library-select', {
    attrs: {
      "label": "Can Materials",
      "items": _vm.$store.state.inventories,
      "item-text": "data.name",
      "item-value": "uuid"
    },
    model: {
      value: _vm.canInventory,
      callback: function ($$v) {
        _vm.canInventory = $$v;
      },
      expression: "canInventory"
    }
  }), _c('library-select', {
    attrs: {
      "label": "Spiral Materials",
      "items": _vm.$store.state.inventories,
      "item-text": "data.name",
      "item-value": "uuid"
    },
    model: {
      value: _vm.spiralInventory,
      callback: function ($$v) {
        _vm.spiralInventory = $$v;
      },
      expression: "spiralInventory"
    }
  }), _c('library-select', {
    attrs: {
      "label": "Load Set",
      "items": _vm.$store.state.loadSets,
      "item-text": "data.name",
      "item-value": "uuid"
    },
    model: {
      value: _vm.loadSet,
      callback: function ($$v) {
        _vm.loadSet = $$v;
      },
      expression: "loadSet"
    }
  }), _c('v-radio-group', {
    attrs: {
      "label": "Target",
      "mandatory": "",
      "row": ""
    },
    model: {
      value: _vm.target,
      callback: function ($$v) {
        _vm.target = $$v;
      },
      expression: "target"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Hub Height",
      "value": "HUB"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Tip Height",
      "value": "BLADE_TIP"
    }
  })], 1), _c('numeric-text-field', {
    attrs: {
      "label": "Max Height",
      "units": "m",
      "required": ""
    },
    model: {
      value: _vm.height,
      callback: function ($$v) {
        _vm.height = $$v;
      },
      expression: "height"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": "",
      "disabled": !_vm.isValid
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Save ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }